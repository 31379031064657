<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        extension-height="10"
        color="grey darken-4"
        dark
      >
        <v-toolbar-title>CP58 Statement</v-toolbar-title>
        <v-spacer />
        <v-select
          v-model="year"
          :items="years"
          label="Year"
          prepend-inner-icon="event"
        />
      </v-toolbar>

      <v-list>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.icon"
          no-action
        >
          <template #activator>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <v-list-tile
            v-for="subItem in item.items"
            :key="subItem.title"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-btn
                flat
                :href="printPDF(subItem.title, subItem.link)"
                target="_blank"
              >
                <v-icon>
                  {{ subItem.icon }}
                </v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </v-list-group>
      </v-list>
    </v-card>
  </panel>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams, createGetUrl } from '@/rest'

const pageTitle = 'CP58 Statement'
export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Downloads', disabled: false, to: '/downloads',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      year: null,
      years: [],
      title: pageTitle,
      items: [],
      loading: false,
    }
  },

  watch:
  {
    year: function (val) {
      this.getCp58Statement(val)
    },

  },

  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    const maxDate = now.toFormat('yyyy')
    for (let year = maxDate; year > 2020; year--) {
      this.years.push(year)
    }
    this.year = maxDate
  },

  methods: {
    printPDF: function (title, link) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/printCP58.php?file=' + link + '&filename=' + title
        : createGetUrl('http://203.115.197.112/api/pdf/printCP58.php?file=' + link + ('&filename=' + title))
    },
    getCp58Statement (year) {
      const params = createGetParams({ year })
      this.$rest.get('getCp58Statement.php', params)
        .then((response) => {
          this.items = response.data
        }).catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
